<template>
	<Sidebar v-if="hasPermission('np.backoffice.users.addperkmembercard')" v-model:visible="createNewSidebarVisible"
		class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			
			<Form  :fields="perkFields" :context="perkContext"
				@onChange="onPerkChanged" :hasAction="false" />
			<Form v-if="perkContext.perkId.id != null" :fields="perkDetailsFields" :context="perkDetailsContext"
				@onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section">

		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.users.addperkmembercard')"
					@click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>
	<Toolbar >
		<template #start>
			<div class="flex-wrap flex gap-4 ">
				<div class="flex w-full ">
				<div class="grid w-14rem">
					<h4 class="w-full mt-1 mb-2 text-gray-700">Valid from</h4>
					<Calendar v-model="dateFilter.from"   class="m-0 p-0" />
		
				</div>
				<div class="grid ml-2">
					<h4 class="w-full mt-1 mb-2 text-gray-700">Valid to</h4>
					<Calendar v-model="dateFilter.to" placeholder="Never"   /> 
					<Button @click="dateFilter.to = ''" aria-label="cancel" icon="pi pi-times"></Button>

				</div>
				</div>
				<div class="flex flex-wrap gap-4">
					<div class="grid ">
						<h4 class="w-full mt-1 mb-2 text-gray-700">Status filter</h4>
						<MultiSelect v-model="selectedStatusFilters" display="chip"  class="w-28rem"
						:options="StatusFilterOptions" :filter="false" :showToggleAll="true" optionLabel="name" placeholder="Select Status types" >
							<template #header>
								<div class="checkbox-all-text">All</div>
							</template>
						</MultiSelect> 
		
					</div>
					<div class="grid">
						<h4 class="w-full mt-1 mb-2 text-gray-700">Type filter</h4>
						<MultiSelect v-model="selectedTypeFilters" display="chip" class="w-28rem"
						:options="TypeFilterOptions" optionLabel="name" placeholder="Select Claim types"  >
							<template #header>
								<div class="checkbox-all-text">All</div>
							</template>
						</MultiSelect>  
		
					</div>
				</div>
			</div>
		</template>
	
	</Toolbar>
		
		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div  class="card mt-2">
	
			<DataTable :value="filteredEntities" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
			
				<Column>
					<template #body="{ data }">
						<img v-if="data.pictureUrl" :src="data.pictureUrl"
							style="width: 60px; height: 60px; object-fit: contain" />
					</template>
				</Column>
				<Column field="cachedPerk.title" header="Product"></Column>
				<!--
          <Column field="cachedPerk.memberCardId.id" header="Card Number"></Column>
        -->
				<Column header="Visible from">
					<template #body="{ data }">
						<div class="text-sm">{{ formatDateTime(data.visibleFrom) }}</div>
					</template>
				</Column>
				<Column header="Valid from">
					<template #body="{ data }">
						<div class="text-sm">{{ formatDateTime(data.validFrom) }}</div>
						<!-- <div class="text-sm">{{ formatDate(data.validFrom) }} - {{data.validTo==null?'Never':formatDate(data.validTo)}}</div> -->
					</template>
				</Column>
				<Column header="Valid to">
					<template #body="{ data }">
						<div v-if="data.validTo != null" class="text-sm">{{ formatDateTime(data.validTo) }}</div>
						<div v-else class="text-sm">Never</div>
					</template>
				</Column>
				<Column field="sentToPos" header="Sent to POS">
					<template #body="{ data }">
						<div class="text-sm">{{formatSendToPosDate(data.sentToPosTimestamp)}}</div>
					</template>
				</Column>
				<Column header="Status & Type">
					<template #body="{ data }">
						<div class="flex flex-wrap gap-1">
							<Chip :label="getPerkStatus(data)" :class="getPerkStatusClass(data)" class="select-none" />
							<Chip :label="data.perkType" class="chip-grey select-none" />		

							<Chip v-if="data.venueId" label="Venue specific" v-tooltip="getVenueName(data.venueId)" class="chip-waiting select-none" />				
							<Chip v-if="data.isFromNightPay" label="From NightPay" class="chip-waiting select-none" />				

						</div>
					</template>
				</Column>
				<!-- <Column field="claimType" header="Type">
					<template #body="{ data }">
						<Chip :label="data.claimType" class="chip-enabled" />
					</template>
				</Column> -->
				<Column header="Created by">
					<template #body="{ data }">
						{{ data.createdByDisplayName ?? 'Automatic' }}
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.users.addperkmembercard')"
							@click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject, computed, watch } from 'vue';

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';


export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'userPerks/getUserPerkListItems';
		const storeGetterGetEntities = 'userPerks/userPerkListItems';
		const storeActionCreateEntity = 'userPerks/add';
		//const storeActionUpdateEntity = 'perks/update'
		const storeActionDeleteEntity = 'userPerks/delete';
		const entityName = 'User Perk';
		const pageTitle = 'Perks';
		const pageDescription = "Here you can manage the User's Perks";
		const canCreateNew = true;
		//const canUpdate = true
		const selectedStatusFilters = ref([{name:"Enabled"}])
		const selectedTypeFilters = ref([{name:"Redeemable"}])

		const StatusFilterOptions = [{name:"Enabled"}, {name: "Redeemed"}, {name:"Expired"}, {name:"Disabled"}, {name:"Waiting for consent"}]
		const TypeFilterOptions = [{name:"Redeemable"}, {name: "NonRedeemable"}, {name:"UnspecifiedRedeemable"}, {name:"RedeemableNoPos"}]


		const perkFields = ref([]);
		const perkDetailsFields = ref([
			{
				id: 'quantity',
				type: 'number-input',
				title: 'Quantity',
			},
			{
				id: 'validFromUtc',
				type: 'time',
				title: 'Valid from',
			},
			{
				id: 'validToUtc',
				type: 'time',
				title: 'Valid to',
			},
			{
				id: 'visibleFromUtc',
				type: 'time',
				title: 'Visible from',
			},
			{
				id: 'venueId',
				type: 'dropdown',
				title: 'Venue',
				filter: 'true',
				config: {
					options: [],
					optionLabel: "name",
					optionValue: "id",
					placeholder: "All"
				},
				help: "Allow perk to be used only on a specific venue or all venues"
			},
			{
				id: 'isFromNightPay',
				type: 'switch',
				title: 'Is from NightPay',
				help: "Sets the perk in 'From NightPay' category in app. Cannot be paired with a venue"
			},
		]);


		const loading = ref(true);
		const creating = ref(false);

		const useFormatDate = inject('useFormatDate');

		const route = useRoute();
		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();
		const entities = ref([]);
		const filteredEntities = computed(()=>{
			return entities.value?.filter((e)=>{
				return selectedStatusFilters.value.findIndex(scope => scope.name == getPerkStatus(e)) > -1 && 
						selectedTypeFilters.value.findIndex(type => type.name == e.perkType) > -1
			})?.sort((a,b) => {
				return new Date(b.validFrom) - new Date(a.validFrom)
			})
		})

		const venues = ref([])

		const userId = route.params.userId;
		const user = ref();
		const perks = ref([]);

		const perkContext = ref({
			perkId: { id: null },
		});

		const validTo = new Date()
		validTo.setDate(validTo.getDate() + 1) 
		validTo.setHours(0, 0, 0, 0)
		

		const perkDetailsContext = ref({
			quantity: 1,
			validFromUtc: null,
			validToUtc: validTo,
			visibleFromUtc: null,
			venueId: null,
			isFromNightPay: false,
		});

		const perkCategories = ref([]);
		const onlinePosCampaigns = ref([]);

		const dateFilter = ref({from: new Date(), to: ""})

		const createNewSidebarVisible = ref(false);

		function isPerkEnabled(perk) {
			const current = new Date();
			console.log('current', current);
			var expired = current > new Date(perk.validTo);
			if (expired && perk.validTo != null) return false;

			var enabled = current > new Date(perk.validFrom);
			if (!enabled) return false;

			if (perk.sentToPos) return false;
			return true;
		}


		function getPerkStatus(perk) {
			const current = new Date();

			if (perk.sentToPos) return "Redeemed";

			var expired = current > new Date(perk.validTo);
			if (expired && perk.validTo != null) return "Expired";

			var enabled = current > new Date(perk.validFrom);
			if (!enabled) return "Disabled";

			if(perk.waitingForConsent) return "Waiting for consent"


			return "Enabled";
		}

		function getPerkStatusClass(perk) {
			const current = new Date();
			
			if (perk.sentToPos) return "chip-redeemed";

			var expired = current > new Date(perk.validTo);
			if (expired && perk.validTo != null) return "chip-expired";

			var enabled = current > new Date(perk.validFrom);
			if (!enabled) return "chip-disabled";

			if(perk.waitingForConsent) return "chip-disabled"

			return "chip-enabled";
		}

		function formatSendToPosDate(date){
				const dateSend = new Date(date);
				if(dateSend.getFullYear() <= 1) return "-";
				return useFormatDate().formatDateTime(dateSend)
		}


		function setStartDate(){
			var newDate = new Date();
			newDate.setMonth(new Date().getMonth() - 3);
			newDate.setHours(0);
			dateFilter.value.from = newDate
		}
	

		function onPerkChanged(context) {
			perkContext.value = context;
		}


		function perkCategoryTitle(perkCategoryId) {
			const perkCategory = store.getters['perkCategories/perkCategory'](perkCategoryId);

			if (perkCategory == null) {
				return 'Unknown Perk Category';
			}

			return perkCategory.title;
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => { },
			});
		}

		async function createEntity(perkDetailsContext) {

			const formContext = { ...perkContext.value, ...perkDetailsContext  };

			creating.value = true;

			try {
				await store.dispatch(storeActionCreateEntity, { userId: userId, payload: formContext });

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
			perkContext.value = {
					perkId: { id: null },
				}
		}

		async function reloadEntities() {
			try {
				var from = dateFilter.value.from
				from.setHours(0);
				var to = dateFilter.value.to ? dateFilter.value.to.toJSON() : undefined
				await store.dispatch(storeActionGetEntities, { userId: userId, from: from.toJSON(), to: to });
				const loadedEntities = store.getters[storeGetterGetEntities](userId);
				entities.value = JSON.parse(JSON.stringify(loadedEntities));
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function onDateChange(){
			loading.value = true;
			await reloadEntities();
			loading.value = false;
		}

		watch(()=> dateFilter.value.from, ()=>{
			onDateChange()
		})
		watch(()=> dateFilter.value.to, ()=>{
			onDateChange()
		})


		async function loadRequiredData() {
			loading.value = true;

			// Load User
			await store.dispatch('users/ensureUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);
			user.value = JSON.parse(JSON.stringify(loadedUser));

			// Load Perks
			await store.dispatch('perks/ensurePerkListItems');
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;

			// Load OnlinePOS Campaigns
			try {
				await store.dispatch('campaigns/ensureCampaignListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Campaigns: ' + err, life: 3000 });
			}
			const loadedCampaigns = store.getters['campaigns/campaignListItems'];
			onlinePosCampaigns.value = loadedCampaigns;

			// Load Perk Categories
			try {
				await store.dispatch('perkCategories/ensurePerkCategoryListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perk Categories: ' + err, life: 3000 });
			}
			const loadedPerkCategories = store.getters['perkCategories/perkCategoryListItems'];
			perkCategories.value = loadedPerkCategories;

			reloadEntities();

			perkFields.value = [
				{
					id: 'perkId',
					path: 'perkId.id',
					type: 'dropdown',
					title: 'Perk',
					config: {
						options: perks,
						optionLabel: 'name',
						optionValue: 'id',
						placeholder: 'Please select Perk',
					},
				},
			];

			await loadVenues();
	
			loading.value = false;
		}

		async function loadVenues(){
			await store.dispatch('venues/ensureVenueListItems');
			const loadedVenues = store.getters['venues/venueListItems'];
			venues.value = loadedVenues;

			var options = [{id: null, name: "All"}, ...venues.value]

			var venueFieldIndex = perkDetailsFields.value.findIndex(x => x.id == "venueId");
			perkDetailsFields.value[venueFieldIndex].config["options"] = options
		}

		function getVenueName(venueId){
			return venues.value?.find(x => x.id == venueId?.id)?.name ?? "Not found"
		}

		onMounted(async () => {
			setStartDate();
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			selectedStatusFilters,
			selectedTypeFilters,
			StatusFilterOptions,
			TypeFilterOptions,
			canCreateNew,
			loading,
			creating,

			perkFields,
			perkDetailsFields,
			perkContext,
			perkDetailsContext,
			onPerkChanged,
			createEntity,
			confirmDeleteEntity,

			perkCategories,
			onlinePosCampaigns,
			perkCategoryTitle,

			entities,
			filteredEntities,
			createNewSidebarVisible,
			dateFilter,
			onDateChange,
			getPerkStatus,
			getPerkStatusClass,
			getVenueName,
			formatSendToPosDate,
			formatDate: useFormatDate().formatDate,
			formatDateTime: useFormatDate().formatDateTime,
			isPerkEnabled,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.checkbox-all-text {
    position: absolute;
    left: 42px;
	top:14px
}
</style>
