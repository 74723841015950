<template>
	<div class="flex flex-column mb-2">
		<p v-if="configs == null || configs.length == 0">Please add a Perk Config</p>
		<div  style="padding-left: 12px">
			<div v-for="(perkConfig, index) in configs" :key="index" class="field border-1 border-gray-300 p-2 border-round shadow-1">
				<label :for="'config-' + index"  class="font-bold text-900" style="font-size: 90%; margin-bottom: 2px">Perk {{ index + 1 }}</label>
				<div class="p-inputgroup">
					<Dropdown v-model="perkConfig.perkId.id" :options="perks" optionLabel="title" optionValue="id" placeholder="Select Perk" />
					<Button @click="deleteConfig(index)" icon="pi pi-times" class="p-button-warning" />
				</div>

				<label v-if="!perkConfig.isFromNightPay" style="font-size: 90%; margin-bottom: 2px" class="mt-2">Venue</label>
				<div  v-if="!perkConfig.isFromNightPay" class="p-inputgroup">
					<Dropdown v-model="perkConfig.venueId.id" :options="venues" optionLabel="name" optionValue="id" placeholder="All" />
				</div>

				<label  style="font-size: 90%; margin-bottom: 2px" class="mt-2">Is from NightPay</label>
				<div class="p-inputgroup">
					<InputSwitch v-model="perkConfig.isFromNightPay" />
				</div>

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-3">Quantity</label>
				<InputNumber v-model="perkConfig.quantity" style="width: 200px" />

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Occurence</label>
				<div class="p-inputgroup">
					<InputNumber v-model="perkConfig.occurence" style="max-width: 200px" />
					<Dropdown
						v-model="perkConfig.occurenceType"
						:options="durationTypes"
						optionLabel="title"
						optionValue="id"
						placeholder="Select duration"
						style="margin-left: 8px; max-width: 200px"
					/>
				</div>

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-3">Expires</label>
				<InputSwitch v-model="perkConfig.expires" />

				<div v-if="perkConfig.expires">
					<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Expires in</label>
					<div class="p-inputgroup">
						<InputNumber v-model="perkConfig.expire" style="max-width: 200px" />
						<Dropdown
							v-model="perkConfig.expireType"
							:options="durationTypes"
							optionLabel="title"
							optionValue="id"
							placeholder="Select duration"
							style="margin-left: 8px; max-width: 200px"
						/>
					</div>
				</div>
				
				<label style="font-size: 90%; margin-bottom: 2px" class="mt-3">Re-Assigns</label>
				<label class="text-xs">Allows the user to use the perk an unlimited amount until specified time</label>
				<InputSwitch v-model="perkConfig.reassigns" />

				<div v-if="perkConfig.reassigns" class="flex flex-column flex-wrap ">
				<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Re-Assigns until</label>
				<div class="flex align-items-center">
				<InputNumber v-model="perkConfig.reassignsUntil"  style="width: 200px" inputId="minmax" suffix=":00" :min="10" :max="24" />
					<i class="pi pi-clock text-primary text-xl p-2 " />
				</div>
				</div>
			</div>
			<Button @click="addConfig" label="Add Perk" class="mt-2 p-button-secondary p-button-sm" />
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';

export default {
	props: ['context', 'field'],
	setup(props) {
		const store = useStore();
		const toast = useToast();

		const loading = ref(true);
		const perks = ref([]);
		const venues = ref([])

		const durationTypes = ref([
			{
				id: 'Day',
				title: 'day(s)',
			},
			{
				id: 'Week',
				title: 'week(s)',
			},
			{
				id: 'Month',
				title: 'month(s)',
			},
			{
				id: 'Year',
				title: 'year(s)',
			},
		]);

		// const configs = ref({});
		// if (props.context.configs != null) {
		//     configs.value = props.context.perkConfigs;
		// } else if (props.context != null){
		//     configs.value = props.context;
		// }

		const configs = props.context.perkConfigs != null ? ref(props.context.perkConfigs) : ref(props.context);

		// const configs = ref(props.context.perkConfigs)
		// const configs = ref(props.context)

		function addConfig() {
			console.log('addConfig', configs.value);
			configs.value.push({
				perkId: {
					id: null,
				},
				venueId: {
					id: null
				},
				quantity: 0,
				occurence: 0,
				occurenceType: 0,
				expires: false,
				expire: 0,
				expireType: 0,
				reassigns: false,
				reassignsUntilTime: "20:00"
			});
		}

		function deleteConfig(index) {
			configs.value.splice(index, 1);
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load Perks
			try {
				await store.dispatch('perks/ensurePerkListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;

			loadVenues();
			loading.value = false;
		}

		async function loadVenues(){
			await store.dispatch('venues/ensureVenueListItems');
			const loadedVenues = store.getters['venues/venueListItems'];

			venues.value = [{id: null, name: "All"}, ...loadedVenues]

		}


		onMounted(async () => {
			await loadRequiredData();
			
		});

		return {
			perks,
			venues,

			durationTypes,

			configs,

			addConfig,
			deleteConfig,
		};
	},
};
</script>
