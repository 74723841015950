import * as apiGetStripeSubscription from '@/api/subscriptions/getStripeSubscriptionInfo'
import * as apiUpdateSubscriptionPlan from '@/api/subscriptions/updateSubscriptionPlan'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        detailedSubscriptions: {}
    }),
    mutations: {

        gotSubscription(state, subscription){
            state.detailedSubscriptions[subscription.subscription.id] = subscription
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
    
        async getSubscription({ commit }, { stripeSubscriptionId }) {
            commit('isLoading', true)

            console.log('Getting Subscription:', stripeSubscriptionId);
        
            const result = await apiGetStripeSubscription.get(stripeSubscriptionId)
            
            if (result) {
                commit('gotSubscription', result)
            }
        
            commit('isLoading', false)

        },


        async updateSubscriptionPlan({ commit }, { userId, subscriptionPlan }) {
            commit('isLoading', true)

        
            await apiUpdateSubscriptionPlan.put(userId, subscriptionPlan)

        
            commit('isLoading', false)

        },


        async ensureSubscription({ commit, dispatch, getters }, { stripeSubscriptionId }) {
            commit('isLoading', true)

            if (!getters.subscription(stripeSubscriptionId)) {
                console.log('Subscription not cached. Getting from API.')
                await dispatch('getSubscription', { stripeSubscriptionId } )
            }

            commit('isLoading', false)
        },

    },
    getters: {
        subscription: (state) => (subscriptionId) => {
            return state.detailedSubscriptions[subscriptionId]?.subscription
        },
        subscriptionInvoices: (state) => (subscriptionId) => {
            return state.detailedSubscriptions[subscriptionId]?.invoices
        },
        isLoading: (state) => state.loading
    }
};