<template>
	<div v-for="perkConfig in perkConfigs" :key="perkConfig.title" class="field border-1 border-gray-300 p-2 border-round shadow-1">
		<div class="flex m-0 p-0">
			<h3 class="text-gray-700 mb-0 pb-0 w-fit">{{perkConfig.title}}</h3>
			<div class="flex flex-wrap ml-auto text-center ">
				<label for="" class="mx-auto w-full ">{{perkConfig.enabled ? 'Enabled' : 'Disabled'}}</label>
				<InputSwitch v-model="perkConfig.enabled" class="mx-auto p-0"></InputSwitch>
			</div>
		</div>
			<p class="pb-3 m-0 text-xs pt-1">{{perkConfig.help}}</p>
			<div v-if="perkConfig.enabled" class="field">
				<div class="p-inputgroup">
					<Dropdown v-model="perkConfig.perkId.id" :options="perks" filter optionLabel="title" optionValue="id" placeholder="Select Perk" />
				</div>

				<label v-if="!perkConfig.isFromNightPay" style="font-size: 90%; margin-bottom: 2px" class="mt-3">Venue</label>
				<div  v-if="!perkConfig.isFromNightPay" class="p-inputgroup">
					<Dropdown v-model="perkConfig.venueId" :options="venues" filter optionLabel="name" optionValue="venueId" placeholder="All" />
				</div>

				<label  style="font-size: 90%; margin-bottom: 2px" class="mt-3">Is from NightPay</label>
				<div class="p-inputgroup">
					<InputSwitch v-model="perkConfig.isFromNightPay" />
				</div>

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-3">Quantity</label>
				<InputNumber v-model="perkConfig.quantity" style="width: 200px" />


				<label style="font-size: 90%; margin-bottom: 2px" class="mt-3">Expires</label>
				<InputSwitch v-model="perkConfig.expires" />

				<div v-if="perkConfig.expires">
					<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Expires in</label>
					<div class="p-inputgroup">
						<InputNumber v-model="perkConfig.expire" style="max-width: 200px" />
						<Dropdown
							v-model="perkConfig.expireType"
							:options="durationTypes"
							optionLabel="title"
							optionValue="id"
							placeholder="Select duration"
							style="margin-left: 8px; max-width: 200px"
						/>
					</div>
				</div>
			</div>	
		</div>
		<Button class="flex-1 mt-3 w-full" @click="onSave" :label="'Update'" :icon="'pi pi-cloud-upload'" />

</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';


export default {
	emits: ['onAction'],
	props: ['context'],
	setup(props, {emit}) {
		
		const store = useStore();

		const perkConfigs = ref(props.context)

		var perks = ref([])
		var venues = ref([])
		const toast = useToast();

		const durationTypes = ref([
			{
				id: 'Day',
				title: 'day(s)',
			},
			{
				id: 'Week',
				title: 'week(s)',
			},
			{
				id: 'Month',
				title: 'month(s)',
			},
			{
				id: 'Year',
				title: 'year(s)',
			},
		]);


		function onSave(){

			if(perkConfigs.value[0].enabled && !perkConfigs.value[0].perkId?.id  || (perkConfigs.value[1].enabled && !perkConfigs.value[1].perkId?.id ))
			{
					toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update subscription perk configurations' ,
					life: 3000,
				});
				return
			}
			

			emit('onAction', perkConfigs.value);
		}
	


		async function loadRequiredData(){
			

			await store.dispatch( 'perks/ensurePerkListItems')
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks
		
			await store.dispatch('venues/ensureVenueListItems');
			const loadedVenues = store.getters['venues/venueListItems'];
			venues.value = [{venueId: null, name: "All"}, ...loadedVenues]

		}


		onMounted(()=>{
			loadRequiredData();
		})
		return {
			perkConfigs,
			onSave,

			venues,
			perks,
			durationTypes

		};
	},
};
</script>
