<template>
  <div
    id="app-sidebar-2"
    class="surface-section hidden lg:block flex-shrink-0 z-1 border-right-1 surface-border select-none h-full"
    style="width: 280px; background-color: #f4f7f8"
  >
    <div class="flex flex-column h-full">
      <div class="flex flex-column align-items-center px-5 mt-4">
        <Button
          @click="toUsers"
          label="Back to Users"
          icon="pi pi-chevron-left"
          class="w-full p-button-secondary p-button-text p-button-sm"
        />
        <p class="text-lg font-semibold">User Settings</p>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0">
          <li v-for="option in config.options" :key="option.title">
            <router-link
              :to="'/user/' + userId + option.slug"
              style="text-decoration: none"
              class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors"
            >
              <i class="fa-fw mr-2" :class="option.icon"></i>
              <span class="font-medium">{{ option.title }}</span>
              <span
                v-if="option.label"
                class="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle"
                style="min-width: 1.5rem; height: 1.5rem"
              >
                {{ option.label }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";

import { useRouter } from "vue-router";

export default {
  props: ["userId"],
  setup() {
    const loading = ref(false);

    const router = useRouter();

    function toUsers() {
      router.push({ name: "users" });
    }

    async function loadRequiredData() {
      loading.value = true;

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      loading,

      toUsers,

      config: computed(() => {
        const options = [
          {
            title: "General",
            icon: "pi pi-info-circle",
            slug: "/general"
          },
          {
            title: "Consents",
            icon: "pi pi-check-square",
            slug: "/consents"
          },
          // {
          //     title: 'Country',
          //     icon: 'pi pi-flag',
          //     slug: '/country'
          // },
          {
            title: "Membership",
            icon: "pi pi-id-card",
            slug: "/membership"
          },
          {
            title: "Member Cards",
            icon: "pi pi-credit-card",
            slug: "/member-cards"
          },
          //   {
          //   title: "Receipts",
          //   icon: "fas fa-receipt",
          //   slug: "/receipts"
          // },
          {
            title: "Notifications",
            icon: "pi pi-inbox",
            slug: "/notifications"
          },
          {
            title: "Perks",
            icon: "pi pi-ticket",
            slug: "/perks"
          },
          {
            title: "Permissions",
            icon: "pi pi-lock",
            slug: "/permissions"
          },
          {
            title: "Event log",
            icon: "pi pi-calendar",
            slug: "/event-log"
          },
          {
            title: "Danger Zone",
            icon: "pi pi-ban",
            slug: "/danger-zone"
          }
        ];

        return {
          options
        };
      })
    };
  }
};
</script>

<style scoped>
.router-link-exact-active .font-medium,
.router-link-exact-active i.pi {
  font-weight: 700 !important;
}
</style>