import apiClient from '@/services/axios'

export async function get(stripeSubscriptionId) {
    
  var response = await apiClient.get('/Subscription/Events', { params: { stripeSubscriptionId } } )
  if (response) {
    return response.data
  }

  throw new Error('Unable to get subscription events')
}
