<template>
	<div v-if="ready">
		<div class="field">
			<!--<label class="font-medium text-sm">Address</label>-->
			<GMapAutocomplete class="p-inputtext" ref="autocomplete" autocomplete="new-location" placeholder="Enter Address" @place_changed="updateAddress"> </GMapAutocomplete>
		</div>

		<div class="field">
			<label class="font-medium text-sm">Street</label>
			<InputText type="text" v-model="data.street" disabled />
		</div>

		<div class="formgrid grid">
			<div class="field col">
				<label class="font-medium text-sm">Postal Code</label>
				<InputText type="text" v-model="data.postalCode" disabled />
			</div>
			<div class="field col">
				<label class="font-medium text-sm">City</label>
				<InputText type="text" v-model="data.city" disabled />
			</div>
		</div>

		<div class="field">
			<label class="font-medium text-sm">Country</label>
			<InputText type="text" v-model="data.country" disabled />
		</div>

		<GMapMap :center="center" :zoom="15" map-type-id="terrain" style="width: 100%; height: 400px">
			<GMapCluster :zoomOnClick="true">
				<GMapMarker :position="center" />
			</GMapCluster>
		</GMapMap>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
	props: ['context'],
	emits: ['onAction', 'onChange'],
	setup(props, { emit }) {
		const autocomplete = ref(null);

		const data = ref(props.context);

		const ready = ref(false);

		const center = ref({ lat: 55.680897, lng: 12.575954 });
		console.log('data:', data.value)
		if (data.value && data.value.latitude != null && data.value.longitude != null) {
			center.value.lat = data.value.latitude
			center.value.lng = data.value.longitude
		}

		const infoContent = ref('');
		const infoWindowPos = ref(null);
		const infoWinOpen = ref(false);
		const currentMidx = ref(null);
		const infoOptions = ref({
			pixelOffset: { width: 0, height: -35 },
		});
		const markers = ref([{ position: { lat: 55.680897, lng: 12.575954 }, infoText: '' }]);

		function updateAddress(payload) {
			console.log('updateAddress', payload);
			const lat = payload.geometry.location.lat();
			const lng = payload.geometry.location.lng();

			center.value.lat = lat;
			center.value.lng = lng;

			markers.value[0].position.lat = lat;
			markers.value[0].position.lng = lng;

			data.value.street = getAddressComponent(payload, 'route') + ' ' + getAddressComponent(payload, 'street_number');
			data.value.city = getAddressComponent(payload, 'locality');
			data.value.postalCode = getAddressComponent(payload, 'postal_code');
			data.value.country = getAddressComponent(payload, 'country');
			data.value.latitude = lat;
			data.value.longitude = lng;

			emit('onChange', data.value);
		}

		function getAddressComponent(address, component) {
			var result = address.address_components.filter((cmp) => {
				if (cmp.types.includes(component)) {
					return true;
				}

				return false;
			});

			if (result.length == 0) {
				return '';
			}

			return result[0].long_name;
		}

		onMounted(() => {
			ready.value = true;
			//autocomplete.value.setAttribute('autocomplete','off')
		});

		return {
			autocomplete,

			data,

			ready,

			center,
			infoContent,
			infoWindowPos,
			infoWinOpen,
			currentMidx,
			infoOptions,
			markers,

			updateAddress,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vue-map-container {
	height: 400px;
}
</style>
