

import * as apiGetAppContent from '@/api/appContent/getAppContent';
import * as apiUpdateAppContent from '@/api/appContent/updateAppContent';
import * as apiCreateAppContent from '@/api/appContent/createAppContent';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
        appContent: null
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
        gotAppContent(state, appContent) {
			state.appContent = appContent
		},
	},
	actions: {
        async getAppContent({ commit }) {
			commit('isLoading', true);

            const result = await apiGetAppContent.get();

			if (result) {
				commit('gotAppContent', result)
			}
			commit('isLoading', false);
		},

        async create({ commit }, appContent) {
			commit('isLoading', true);

			await apiCreateAppContent.post(appContent);

			commit('isLoading', false);
		},
		
        async update({ commit }, appContent) {
			commit('isLoading', true);

			await apiUpdateAppContent.put(appContent.id, appContent);

			commit('isLoading', false);
		},
	},
	getters: {
        appContent: (state) => state.appContent,

    },
};