import * as apiGetSSubscriptionEvents from '@/api/subscriptions/getUserSubscriptionEvents'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        subscriptionEvents: []
    }),
    mutations: {

        gotSubscriptionEvents(state, events){
            state.subscriptionEvents = events
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
    
        async getSubscriptionEvents({ commit }, { stripeSubscriptionId }) {
            commit('isLoading', true)

            console.log('Getting Subscription events for:', stripeSubscriptionId);
        
            const result = await apiGetSSubscriptionEvents.get(stripeSubscriptionId)
            
            if (result) {
                commit('gotSubscriptionEvents', result)
            }
        
            commit('isLoading', false)

        },



    },
    getters: {
        subscriptionEvents: (state) => state.subscriptionEvents,
        isLoading: (state) => state.loading
    }
};