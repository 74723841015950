const { BlobServiceClient } = require('@azure/storage-blob')

export async function uploadFile (file, container, id, store) {
	try {

 
		const sasUri = await store.dispatch('files/requestUpload', { name: file.name, container});
		const decodedSasUri = sasUri;

		const blobServiceClient = new BlobServiceClient(decodedSasUri);

		// Create a unique name for the container
		const containerName = id;

		// Get a reference to a container
		const containerClient = blobServiceClient.getContainerClient(containerName);

		const blockBlobClient = containerClient.getBlockBlobClient(file.name);

        await blockBlobClient.uploadData(file);

		var url = new URL(blockBlobClient.url);

		return {
			name: file.name,
			mimeType: file.type,
			size: file.size,
			url: url.origin + url.pathname,
		};
	} catch (error) {
		console.log(error.message);
		return null;
	}
}
