

const hasNoMembership = (subscriptionTier) => subscriptionTier == "NoMembership";

const hasNextSubscriptionTier = (subscriptionTier) => subscriptionTier == "Gold" || subscriptionTier == "Silver" 

const nextSubscriptionTier = (subscriptionTier) => {
    switch(subscriptionTier){
        case "Blue": 
        return "Silver";
        case "Silver":
        return "Gold";
        case "Gold":
        return "Platinum";
    }
}
const calcDateForUpgrade = (period, type, startDate) => {
       
        var date = new Date(startDate);
        if(type == "Month"){
           date.setDate(date.getDate() + period * 30)
        } else {
           date.setDate(date.getDate() + period)
        }
        return new Date(date)
    }

const getColorForSubscriptionTier = (subscriptionTier) => {
    switch(subscriptionTier){
        case "Blue": 
        return "text-blue-400";
        case "Silver":
        return "text-bluegray-400";
        case "Gold":
        return "text-yellow-600";
        default: 
        return "text-bluegray-900";
    }
}

const getSubscriptionEventTitle = (event, val) => {
    switch(event){
        case "SubscriptionStarted": 
         return "Subscription started";
        case "SubscriptionCancelled":
         return "Subscription cancelled";
        case "SubscriptionCancelledImmediately":
           return "Subscription Cancelled immediately By Admin";
        case "SubscriptionCancelledAfterPeriod":
          return "Subscription Cancelled after period end By Admin"
        case "SubscriptionCancelledFromApp":
          return "User unsubscribed from App";
        case "FreedaysAdded":
          return val ? val + " Freedays added to user" : "Freedays added to user" ;
        case "FreedaysExpired":
           return "Freedays expired";
        case "MembershipTierChanged":
           return "MembershipTier changed";
        case "MembershipTierChangedByAdmin":
            return "MembershipTier changed by Admin"
        case "MembershipPaused":
            return `Membership paused by user  for ${val} ${val > 1 ? 'months' : 'month'}`
        case "MembershipResumed":
            return "Membership resumed by user"
        case "MembershipPausedByAdmin":
            return `Membership paused by Admin for ${val} ${val > 1 ? 'months' : 'month'}`
        case "MembershipResumedByAdmin":
            return "Membership resumed by Admin"
        case "MembershipPauseProlonged":
            return `Membership pause prolonged by ${val} ${val > 1 ? 'months' : 'month'}`
        case "MembershipPauseProlongedByAdmin": 
            return `Membership pause prolonged by Admin by ${val} ${val > 1 ? 'months' : 'month'}`
        default: 
          return "Unknown event";
    }
}

const getColorOfMembershipFeeTag = (invoice) => {
    if(invoice?.amount < 0) {
        return '#318CE7'
    }

    if(invoice?.refundStatus == "Refunded" || invoice?.refundStatus == "PartlyRefunded" )
        return '#318CE7'
        


    return invoice.paid ? '#4BB543' : '#ed5249'
}


const getTextOfMembershipFeeTag = (invoice) => {
    if(invoice?.amount < 0) 
        return 'Credit for free days'

    if(invoice?.refundStatus == "Refunded")
        return 'REFUNDED'

    if(invoice?.refundStatus == "PartlyRefunded")
        return 'PARTLY REFUNDED'

    return invoice?.paid ? 'PAID' : 'UNPAID'
}

const getStripeSubscriptionStatus = (status, trialStart, trialEnd, subscriptionStart, events, isPaused) => {
    if(isPaused)
        return "Paused"


    if(status == "Past Due")   
        return "Overdue" 


    if (status != "Trialing")
        return status;
    
    
    var isInitialTrialPeriod = new Date(trialStart)?.toDateString() == new Date(subscriptionStart)?.toDateString()

    if(!isInitialTrialPeriod)
        return "Active (free days)";

    var isExtended = events?.find((x) => x.status == "FreedaysAdded")
     
    
    if(isExtended)
        return "Trialing (extended with free days)"
    
    return "Trialing"
}


export {hasNoMembership, getSubscriptionEventTitle,getColorOfMembershipFeeTag, getTextOfMembershipFeeTag, getStripeSubscriptionStatus, hasNextSubscriptionTier, nextSubscriptionTier, calcDateForUpgrade, getColorForSubscriptionTier}