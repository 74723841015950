import * as Vue from 'vue'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueClipboard from 'vue3-clipboard'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import TreeSelect from 'primevue/treeselect';
import OverlayPanel from 'primevue/overlaypanel';
import InputNumber from 'primevue/inputnumber';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import SpeedDial from 'primevue/speeddial';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import TieredMenu from 'primevue/tieredmenu';
import Timeline from 'primevue/timeline';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import ToggleButton from 'primevue/togglebutton';
import TreeTable from 'primevue/treetable';
import Rating from 'primevue/rating';
import Image from 'primevue/image';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// INIT APPLICATION /////
import App from './App.vue'
const app = Vue.createApp(App)
app.provide('registerComponent', app.component)

app.use(PrimeVue, { ripple: true })
app.use(autoAnimatePlugin)
app.config.globalProperties.$appState = Vue.reactive({ theme: 'mira', dark: false })
app.directive('styleclass', StyleClass);

app.use(ToastService)
app.use(ConfirmationService);
app.use(VueTelInput)
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart)
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);

app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('ConfirmDialog', ConfirmDialog)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('DataViewLayoutOptions', DataViewLayoutOptions)
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Image', Image);

app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('TreeSelect', TreeSelect);
app.component('OverlayPanel', OverlayPanel)
app.component('InputNumber', InputNumber);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('ScrollPanel', ScrollPanel);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('SpeedDial', SpeedDial);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu)
app.component('Timeline', Timeline);
app.component('ToggleButton', ToggleButton)
app.component('Toolbar', Toolbar)
app.component('TreeTable', TreeTable)
app.component('Rating', Rating)
app.component('SelectButton', SelectButton)
app.component('Toast', Toast);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
/////////////////////////

// INIT STORE /////
import store from './store'
app.use(store)
///////////////////

// INIT ROUTER /////
import router from './router/router'
app.use(router)
app.provide('router', router)
////////////////////

// AUTH /////////////////
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/navigationClient";

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  console.log("Setting active account")
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(async (event) => {
  //console.log('msalInstance.addEventCallback:', event.eventType)
  //alert('eventType: ' + event.eventType)
  /*if (event.eventType === EventType.LOGIN_FAILURE && event.payload) {
      console.log('LOGIN_FAILURE:', event)
      alert('LOGIN_FAILURE')
  } 
  else*/ if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
    localStorage.setItem("rekom.nightpay.accessToken", event.payload.accessToken)
    //console.log('ACQUIRE_TOKEN_SUCCESS: Setting Access Token:', event.payload.accessToken)

    // Set MSAL Account
    const payload = event.payload
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
  else if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    //console.log("LOGIN_SUCCESS:", event.payload)
    //console.log('ACCESS TOKEN:', event.payload.accessToken)

    localStorage.setItem("rekom.nightpay.accessToken", event.payload.accessToken)
    //console.log('LOGIN_SUCCESS: Setting Access Token:', event.payload.accessToken)

    // Set MSAL Account
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
})
/////////////////////////

app.use(VuePlyr)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(Vue3VideoPlayer)
app.use(vueVimeoPlayer)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBQl4BHWWfLbaRu5f_UQJUh77mJkPPNn9A',
    libraries: 'places'
  }
})

// Global Components registration ////////////////////
import Loading from './components/Loading'
app.component('Loading', Loading)

import NavBar from './components/NavBar.vue'
app.component('NavBar', NavBar)

import Breadcrumbs from './components/Breadcrumbs.vue'
app.component('Breadcrumbs', Breadcrumbs)

import LeftMenuLayout from './layouts/LeftMenuLayout'
app.component('LeftMenuLayout', LeftMenuLayout)

import Form from '@/components/Form'
app.component('Form', Form)

import GoogleMapsAddress from '@/components/GoogleMapsAddress'
app.component('GoogleMapsAddress', GoogleMapsAddress)

import PerkConfigs from '@/pages/promoCodes/components/PerkConfigs'
app.component('PerkConfigs', PerkConfigs)

import BenefitConfigs from '@/pages/member-cards/components/BenefitConfigs'
app.component('BenefitConfigs', BenefitConfigs)

import MemberCardConfigs from '@/pages/promoCodes/components/MemberCardConfigs'
app.component('MemberCardConfigs', MemberCardConfigs)

import RolePerkConfigs from '@/pages/roles/components/PerkConfigs'
app.component('RolePerkConfigs', RolePerkConfigs)

////////////////////

// Authenticate and start
app.use(msalPlugin, msalInstance)
router.isReady().then(() => {
  console.log("Router is ready")

  // Waiting for the router to be ready prevents race conditions when 
  // returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
})


app.provide("hasPermission", (permId) => {
  return store.getters.hasPermission(permId);
})

app.provide("hasRole", (role) => {
  return store.getters.hasRole(role);
})
app.provide("hasPermissionForEntity", (permId, forEntityId) => {
  return store.getters.hasPermissionForEntity(permId, forEntityId);
})